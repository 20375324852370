import * as Sentry from '@sentry/sveltekit'
import { PUBLIC_SENTRY_ENABLED, PUBLIC_SENTRY_DSN } from '$env/static/public'

if (Boolean(PUBLIC_SENTRY_ENABLED).valueOf()) {
  Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    tracesSampleRate: 0.1
  })
}

const handleError = Sentry.handleErrorWithSentry()

export {
  handleError
}
